<template>
  <div id="app">
    <header>
      
      <nav
        class="flex items-center justify-between flex-wrap p-6 container mx-auto 2xl:max-w-screen-xl"
      >
        <div class="flex items-center flex-shrink-0 mr-6">
          <svg
            class="fill-current h-8 -mr-1 -ml-5"
            width="40"
            height="40"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 67.9371h31M59.002 67.1666l12.4868-33.1179"
              stroke="#2c3e50"
              stroke-width="15"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="font-semibold text-2xl tracking-tight font-mono">
            <a href="/">Restimuli</a>
          </span>
        </div>
        <div class="block lg:hidden">
          <button
            class="flex items-center px-3 py-2 border rounded border-gray-600 hover:text-black hover:border-black focus:outline-none"
            id="nav-toggle"
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          class="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden"
          id="nav-content"
        >
          <div
            class="text-base lg:flex-grow lg:flex lg:justify-end lg:mr-4 font-medium"
          >
            <a
              href="/submit"
              class="block mt-4 lg:inline-block lg:mt-0 hover:text-black mr-8"
              >Want to see your company profile here?</a
            >
            <a
              href="/newsletter"
              class="block mt-4 lg:inline-block lg:mt-0 hover:text-black mr-8"
              >Newsletter</a
            >
            <a
              href="/manifesto"
              class="block mt-4 lg:inline-block lg:mt-0 hover:text-black mr-8"
              >Manifesto</a
            >
            <!-- <a
              href="/covid"
              class="block mt-4 lg:inline-block lg:mt-0 rounded py-0 px-1 hover:text-gray-800 bg-custom-pink text-white text-sm pt-xsmall fit-content mr-3"
              >COVID-19</a
            > -->
            <a
              href="https://publication.restimuli.com/" target="_blank"
              class="block mt-4 lg:inline-block lg:mt-0 rounded py-0.5 px-1.5 hover:text-gray-800 bg-custom-pink text-white text-sm fit-content mr-3"
              >Publication</a
            >
          </div>
          <div>
            <a
              href="/submit"
              class="inline-block text-base px-4 py-2 leading-none border-2 rounded text-white border-white hover:border-transparent hover:text-orange-700 hover:bg-white mt-4 lg:mt-0 foResourcent-black"
              >Create Profile</a
            >
          </div>
        </div>
      </nav>
    </header>
    <!--Hero-->
    <div
      class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center mb-8 sm:mb-0 mt-16 2xl:max-w-screen-xl"
    ></div>

    <div class="relative -mt-12 lg:-mt-24">
      <svg
        viewBox="0 0 1428 174"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="w-full h-28 sm:h-32 md:h-40 lg:h-48 xl:h-64"
        preserveAspectRatio="none"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            transform="translate(-2.000000, 44.000000)"
            fill="#f8fafc"
            fill-rule="nonzero"
          >
            <path
              d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
              opacity="0.100000001"
            />
            <path
              d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
              opacity="0.100000001"
            />
            <path
              d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
              id="Path-4"
              opacity="0.200000003"
            />
          </g>
          <g
            transform="translate(-4.000000, 76.000000)"
            fill="#F4F4F5"
            fill-rule="nonzero"
          >
            <path
              d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
            />
          </g>
        </g>
      </svg>
    </div>

    <section class="bg-gray-100 pt-12 pb-20 -mt-0.5">
      <div class="container mx-auto px-2 pt-4 pb-6 text-gray-800">
        <h1
          class="w-full mt-2 text-9xl font-bold leading-tight text-center text-gray-800"
        >
          404
        </h1>
        <div class="w-full mb-4">
          <div
            class="h-1 mx-auto gradient w-64 opacity-25 py-0 rounded-t -mt-5"
          ></div>
        </div>
        <h4
          class="text-xl font-bold leading-tight text-gray-800 mb-4 mt-10 text-center"
        >
          Oops! We can't seem to find the page you are looking for.
        </h4>
      </div>

      <div
        class=" container  mx-auto mt-12 max-w-sm"
      >
        <a href="/">
          <div class="  p-4 border border-bluegray-300 border-b-2 rounded-md max-w-sm mb-4 hover:border-gray-400">
            <h2 class="text-xl font-bold leading-tight text-gray-800 mb-4">
              > Restimuli
            </h2>
            <!-- <p>
              Find organizations offering essential industries resources.
            </p> -->
            <h3>
              Find companies that share your values.
            </h3>
          </div>
        </a>
        <!-- <a href="/covid">  
          <div class=" p-4 border border-bluegray-300 border-b-2 rounded-md max-w-sm mb-4 hover:border-gray-400">
            <h4 class="text-xl font-bold leading-tight text-gray-800 mb-4">
              > COVID-19
            </h4>
            <p>
              Help with the response to COVID-19.
            </p>
          </div>
        </a> -->
        <a href="/submit">
          <div class=" p-4 border border-bluegray-300 border-b-2 rounded-md max-w-sm mb-4 hover:border-gray-400">
            <h2 class="text-xl font-bold leading-tight text-gray-800 mb-4">
              > Create Profile
            </h2>
            <h3>
              Create a Restimuli profile for your company.
            </h3>
          </div>
        </a>
        <a href="/submit">
          <div class=" p-4 border border-bluegray-300 border-b-2 rounded-md max-w-sm hover:border-gray-400">
            <h2 class="text-xl font-bold leading-tight text-gray-800 mb-4">
              > Check our Publication
            </h2>
            <!-- <p>
              ESG, CSR, sustainability and essential industries inside.
            </p> -->
            <h3>
              ESG, CSR and sustainability inside.
            </h3>
          </div>
        </a>
      </div>
    </section>

    <!-- Change the colour #f8fafc to match the previous section colour -->
    <svg
      class="wave-top"
      viewBox="0 0 1439 147"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
          <g class="wave" fill="#F4F4F5">
            <path
              d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"
            />
          </g>
          <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
            <g
              transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) "
            >
              <path
                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                opacity="0.100000001"
              />
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              />
              <path
                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                opacity="0.200000003"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>

    <section class="container mx-auto text-center pt-6 mb-8">
      <!-- <h1
        class="w-full my-2 text-5xl font-bold leading-tight text-center text-white"
      >
        This list is continuously updated
      </h1>-->
      <!-- <div class="w-full mb-4">
        <div
          class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"
        ></div>
      </div>-->

      <h2 class="my-4 mt-8 text-3xl leading-tight">
        Market your products and company values online
      </h2>

      <p class="mt-12 mb-8">
        <a
          class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-lg py-4 px-8 shadow-lg"
          href="/submit"
          target="_blank"
          >Create a profile</a
        >
      </p>

      <div class="w-full mb-4 mt-20">
        <div
          class="h-1 mx-auto bg-white w-4/6 opacity-25 my-0 py-0 rounded-t"
        ></div>
      </div>

      <form class="w-full max-w-md mx-auto mt-16" action="https://restimuli.us5.list-manage.com/subscribe/post" method="POST" target="_blank">
        <input type="hidden" name="u" value="f82c7f82dbca46f5f83765b6a">
        <input type="hidden" name="id" value="36cf36b57d">
        <div class="flex md:flex-nowrap flex-wrap items-center py-2">          
          <input
            class="appearance-none bg-white w-11/12 lg:w-full lg:mr-3 py-2 px-2 focus:outline-none focus:bg-gray-100 focus:border-orange-900 text-sm placeholder-orange-600 newsletter_placeholder border-2 border-white rounded mx-auto"
            type="email"
            :placeholder="' ' + icon + '   Enter your email...'"
            aria-label="email" autocapitalize="off" autocorrect="off" name="MERGE0" id="MERGE0" size="25" value="">
          <button
            class="flex-shrink-0 bg-custom-pink hover:bg-orange-900 border-custom-pink hover:border-orange-900 text-sm border-2 text-white py-2 px-2 rounded font-medium mx-auto mt-4 md:mt-0 focus:outline-none"
            type="submit"
          >
            Get new profiles
          </button>
        </div>
      </form>

      <div class="text-xs text-orange-300 mt-5">
        <span>
          Join Restimuli's newsletter. By joining, you agree to the
          <a href="/legal" class>Terms of Service</a>
        </span>
      </div>

      <!-- Footer code -->

      <div class="mt-20 text-left">
        <div
          class="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-center"
        >
          <div class="p-5 w-40">
            <div class="text-xs uppercase text-white font-medium">Product</div>
            <a class="my-3 block" href="/submit"
              >Want to see your company profile at Restimuli?<span
                class="text-white text-xs p-1"
              ></span></a
            >
            <!-- <a class="my-3 block" href="/covid"
              >COVID-19 <span class="text-white text-xs p-1">New</span></a
            > -->
          </div>
          <div class="p-5 w-42">
            <div class="text-xs uppercase text-white font-medium">
              Create a profile
            </div>
            <a class="my-3 block" href="/contact"
              >Contact sales <span class="text-white text-xs p-1"></span></a
            >
            <a class="my-3 block" target=”_blank” href="https://publication.restimuli.com/memo/"
              >Suppliers memo  <span class="text-white text-xs p-1">New</span></a
            >
            <!-- <a class="my-3 block" href="/consulting"
              >Book a first 1:1 consultation<span class="text-white text-xs p-1"
                >New</span
              ></a
            > -->
          </div>
          <div class="p-5 w-40">
            <div class="text-xs uppercase text-white font-medium">
              Info
            </div>
            <a class="my-3 block" href="/legal"
              >Privacy & terms <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/contact" target="_blank"
              >Contact us <span class="text-white text-xs p-1"></span
            ></a>
          </div>
          <div class="p-5 w-48 sm:w-40 ">
            <div class="text-xs uppercase text-white font-medium">On us</div>
            <!-- <a class="my-3 block" href="https://twitter.com/restimuli" target="_blank"
              >About us <span class="text-white text-xs p-1"></span>
              </a> -->
            <a class="my-3 block" href="https://publication.restimuli.com/" target="_blank">
              Publication
              <span class="text-white text-xs p-1">New</span>
            </a>  
            <a class="my-3 block" href="/manifesto"
              >Manifesto
            </a>
              <a class="my-3 block" href="/newsletter"
              >Newsletter <span class="text-white text-xs p-1">New</span></a
            >
          </div>
          <!-- <div class="p-5 w-48">
            <div class="text-xs uppercase text-white font-medium">Home</div>
            <a class="my-3 block" href="/#"
              >Services <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Products <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >About Us <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Pricing <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Partners <span class="text-white text-xs p-1">New</span></a
            >
          </div> -->
          <!-- <div class="p-5 w-48">
            <div class="text-xs uppercase text-white font-medium">User</div>
            <a class="my-3 block" href="/#"
              >Sign in <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >New Account <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Demo <span class="text-white text-xs p-1">New</span></a
            ><a class="my-3 block" href="/#"
              >Career
              <span class="text-white text-xs p-1">We're hiring</span></a
            ><a class="my-3 block" href="/#"
              >Surveys <span class="text-white text-xs p-1">New</span></a
            >
          </div> -->
          <!-- <div class="p-5 w-48">
            <div class="text-xs uppercase text-white font-medium">
              Resources
            </div>
            <a class="my-3 block" href="/#"
              >Documentation <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Tutorials <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Support <span class="text-white text-xs p-1">New</span></a
            >
          </div> -->
          <!-- <div class="p-5 w-48">
            <div class="text-xs uppercase text-white font-medium">
              Product
            </div>
            <a class="my-3 block" href="/#"
              >Our Products <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Great Deals <span class="text-white text-xs p-1">New</span></a
            ><a class="my-3 block" href="/#"
              >Analytics <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Mobile <span class="text-white text-xs p-1"></span
            ></a>
          </div> -->
          <!-- <div class="p-5 w-48">
            <div class="text-xs uppercase text-white font-medium">
              Support
            </div>
            <a class="my-3 block" href="/#"
              >Help Center <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Privacy Policy <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >Conditions <span class="text-white text-xs p-1"></span
            ></a>
          </div> -->
          <!-- <div class="p-5 w-48">
            <div class="text-xs uppercase text-white font-medium">
              Contact us
            </div>
            <a class="my-3 block" href="/#"
              >XXX XXXX, Floor 4 San Francisco, CA
              <span class="text-white text-xs p-1"></span></a
            ><a class="my-3 block" href="/#"
              >contact@company.com <span class="text-white text-xs p-1"></span
            ></a>
          </div> -->
        </div>
      </div>
      <!-- End of Footer code -->

      <div class="pt-2 text-left">
        <div
          class="flex pb-5 px-3 m-auto pt-5 border-t border-orange-300 text-gray-800 text-sm flex-col md:flex-row max-w-6xl"
        >
          <div class="mt-2">
            © Copyright {{ new Date().getFullYear() }} Restimuli. All Rights
            Reserved.
          </div>
          <div class="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
            <a href="https://twitter.com/restimuli" target="_blank" class="w-6 mx-1">
              <svg
                class="fill-current cursor-pointer text-white hover:text-gray-100"
                width="100%"
                height="100%"
                viewBox="0 0 1000 1000"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
              >
                <path
                  id="Twitter"
                  d="M500 990c270.6 0 490-219.4 490-490S770.6 10 500 10 10 229.4 10 500s219.4 490 490 490zM356 374.5c94.2 47.6 173.8 44.8 173.8 44.8s-30.2-105.2 63.1-151.8c93.3-46.6 157.5 32 157.5 32s16.3-4.5 28.4-8.9c12.1-4.5 29.7-12.4 29.7-12.4l-28.7 51.3 44.3-4.7s-5.5 8-23.2 24.2-25 24.6-25 24.6 6.3 126.6-60.4 224.1-152.9 156-278.1 168.3-206.7-38.4-206.7-38.4 54.7-3.1 89.6-16.5c34.9-13.3 85.1-48.5 85.1-48.5s-71.3-22-97-46.6c-25.6-24.7-32-39.3-32-39.3l70.4-.9s-74.1-39.3-95.1-70.4c-21-31.1-23.8-61.3-23.8-61.3L282 466s-45-61.2-51.4-108.8c-6.4-47.6 8.2-73.2 8.2-73.2s23 42.9 117.2 90.5z"
                />
              </svg>
            </a>
            <!-- <a href="/#" class="w-6 mx-1">
              <svg
                class="fill-current cursor-pointer text-white hover:text-gray-100"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
              >
                <path
                  id="Facebook"
                  d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669c1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z"
                ></path>
              </svg>
            </a>-->
            <!-- <a href="/#" class="w-6 mx-1">
              <svg
                class="fill-current cursor-pointer text-gray-500 hover:text-gray-400"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
              >
                <g id="Layer_1">
                  <circle id="Oval" cx="12" cy="12" r="12"></circle>
                  <path
                    id="Shape"
                    d="M19.05,8.362c0,-0.062 0,-0.125 -0.063,-0.187l0,-0.063c-0.187,-0.562
                     -0.687,-0.937 -1.312,-0.937l0.125,0c0,0 -2.438,-0.375 -5.75,-0.375c-3.25,0
                     -5.75,0.375 -5.75,0.375l0.125,0c-0.625,0 -1.125,0.375
                     -1.313,0.937l0,0.063c0,0.062 0,0.125 -0.062,0.187c-0.063,0.625 -0.25,1.938
                     -0.25,3.438c0,1.5 0.187,2.812 0.25,3.437c0,0.063 0,0.125
                     0.062,0.188l0,0.062c0.188,0.563 0.688,0.938 1.313,0.938l-0.125,0c0,0
                     2.437,0.375 5.75,0.375c3.25,0 5.75,-0.375 5.75,-0.375l-0.125,0c0.625,0
                     1.125,-0.375 1.312,-0.938l0,-0.062c0,-0.063 0,-0.125
                     0.063,-0.188c0.062,-0.625 0.25,-1.937 0.25,-3.437c0,-1.5 -0.125,-2.813
                     -0.25,-3.438Zm-4.634,3.927l-3.201,2.315c-0.068,0.068 -0.137,0.068
                     -0.205,0.068c-0.068,0 -0.136,0 -0.204,-0.068c-0.136,-0.068 -0.204,-0.204
                     -0.204,-0.34l0,-4.631c0,-0.136 0.068,-0.273 0.204,-0.341c0.136,-0.068
                     0.272,-0.068 0.409,0l3.201,2.316c0.068,0.068 0.136,0.204
                     0.136,0.34c0.068,0.136 0,0.273 -0.136,0.341Z"
                    style="fill: rgb(255, 255, 255);"
                  ></path>
                </g>
              </svg>
            </a>-->
            <!-- <a href="/#" class="w-6 mx-1">
              <svg
                class="fill-current cursor-pointer text-white hover:text-gray-100"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
              >
                <path
                  id="Linkedin"
                  d="M7.3,0.9c1.5,-0.6 3.1,-0.9 4.7,-0.9c1.6,0 3.2,0.3 4.7,0.9c1.5,0.6 2.8,1.5
                  3.8,2.6c1,1.1 1.9,2.3 2.6,3.8c0.7,1.5 0.9,3 0.9,4.7c0,1.7 -0.3,3.2
                  -0.9,4.7c-0.6,1.5 -1.5,2.8 -2.6,3.8c-1.1,1 -2.3,1.9 -3.8,2.6c-1.5,0.7
                  -3.1,0.9 -4.7,0.9c-1.6,0 -3.2,-0.3 -4.7,-0.9c-1.5,-0.6 -2.8,-1.5
                  -3.8,-2.6c-1,-1.1 -1.9,-2.3 -2.6,-3.8c-0.7,-1.5 -0.9,-3.1 -0.9,-4.7c0,-1.6
                  0.3,-3.2 0.9,-4.7c0.6,-1.5 1.5,-2.8 2.6,-3.8c1.1,-1 2.3,-1.9
                  3.8,-2.6Zm-0.3,7.1c0.6,0 1.1,-0.2 1.5,-0.5c0.4,-0.3 0.5,-0.8 0.5,-1.3c0,-0.5
                  -0.2,-0.9 -0.6,-1.2c-0.4,-0.3 -0.8,-0.5 -1.4,-0.5c-0.6,0 -1.1,0.2
                  -1.4,0.5c-0.3,0.3 -0.6,0.7 -0.6,1.2c0,0.5 0.2,0.9 0.5,1.3c0.3,0.4 0.9,0.5
                  1.5,0.5Zm1.5,10l0,-8.5l-3,0l0,8.5l3,0Zm11,0l0,-4.5c0,-1.4 -0.3,-2.5
                  -0.9,-3.3c-0.6,-0.8 -1.5,-1.2 -2.6,-1.2c-0.6,0 -1.1,0.2 -1.5,0.5c-0.4,0.3
                  -0.8,0.8 -0.9,1.3l-0.1,-1.3l-3,0l0.1,2l0,6.5l3,0l0,-4.5c0,-0.6 0.1,-1.1
                  0.4,-1.5c0.3,-0.4 0.6,-0.5 1.1,-0.5c0.5,0 0.9,0.2 1.1,0.5c0.2,0.3 0.4,0.8
                  0.4,1.5l0,4.5l2.9,0Z"
                ></path>
              </svg>
            </a>-->
            <!-- <a href="/#" class="w-6 mx-1">
              <svg
                class="fill-current cursor-pointer text-white hover:text-gray-100"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
              >
                <path
                  id="Combined-Shape"
                  d="M12,24c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12c-6.627,0
                  -12,5.373 -12,12c0,6.627 5.373,12 12,12Zm6.591,-15.556l-0.722,0c-0.189,0
                  -0.681,0.208 -0.681,0.385l0,6.422c0,0.178 0.492,0.323
                  0.681,0.323l0.722,0l0,1.426l-4.675,0l0,-1.426l0.935,0l0,-6.655l-0.163,0l-2.251,8.081l-1.742,0l-2.222,-8.081l-0.168,0l0,6.655l0.935,0l0,1.426l-3.74,0l0,-1.426l0.519,0c0.203,0
                  0.416,-0.145 0.416,-0.323l0,-6.422c0,-0.177 -0.213,-0.385
                  -0.416,-0.385l-0.519,0l0,-1.426l4.847,0l1.583,5.704l0.042,0l1.598,-5.704l5.021,0l0,1.426Z"
                ></path>
              </svg>
            </a>-->
          </div>
        </div>
      </div>

      
    </section>

    <!-- <div class="fixed bottom-0 right-0">
      <a
        class="flex items-center gradient px-3 py-2 rounded-tl-lg text-white border-t border-l"
        href="https://twitter.com/celue"
      >
        <img class="w-6 rounded-full mr-2" src="/pat.jpg" /> Created by Pat
        Eskinasy
      </a>
    </div> -->

    <div class="fixed bottom-0 right-0 flex items-center gradient px-3 py-2 rounded-tl-lg border-t border-l">
      <span class="text-white font-semibold text-white "> Say hi</span> 
        
      <a class="mx-3 flex flex-row bg-white pr-1 py-0.5 rounded-md text-sm font-semibold" href="https://twitter.com/celue" target="_blank"><img class="w-6 rounded-full mx-1" src="/pat.jpg" />
      <span class="mt-0.5">@celue</span>
      </a>
      
      <a class="mr-2 bg-white px-1 py-1 rounded-md text-sm font-semibold" href="https://twitter.com/restimuli" target="_blank"><span class="align-middle">@restimuli</span></a>
    </div>




  </div>
</template>

<script>
import { format } from "timeago.js";

const BUILD_DATE = process.env.VUE_APP_BUILD_DATE;
const COMMIT_HASH = process.env.VUE_APP_COMMIT_HASH;

export default {
  name: "App",
  data() {
    return {
      error: null,
      loading: true,
      buildDate: BUILD_DATE ? format(BUILD_DATE) : null,
      commitHash: COMMIT_HASH,
      show: ["donating", "", "ads"],
      category: null,
      icon: "\uf0e0",
    };
  },

  // computed: {

  // },

  methods: {
    cleanKey(key) {
      return key
        .toLowerCase()
        .replace(/[^a-z]+/gi, "_")
        .replace(/(^_|_$)/g, "");
    },
    cleanValue(key, value) {
      if (key === "category" && value.startsWith("Other")) return "Other";
      return value;
    },
    toggle(type) {
      const add = !this.show.includes(type);
      if (add) this.show.push(type);
      else this.show.splice(this.show.indexOf(type), 1);
    },
  },

  mounted() {
    //Javascript to toggle the menu
    document.getElementById("nav-toggle").onclick = function() {
      document.getElementById("nav-content").classList.toggle("hidden");
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

#tags-container {
  justify-content: space-between;
}

@media (max-width: 1000px) {
  #tags-container {
    flex-wrap: wrap;
  }
}

#tag-list {
  /* width: 100%; */
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}

@media (max-width: 1000px) {
  #tag-list {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  #tag-list {
    flex-direction: row;
  }
}

@media (max-width: 1000px) {
  #tag-column {
    flex-direction: column;
    min-width: 49.68%;
  }
}

@media (max-width: 600px) {
  #tag-column {
    display: inline-block;
    min-width: 250px;
    width: auto;
  }
}

#tag-list-header {
  border-bottom: 2px solid #e6e6e6;
  width: fit-content;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>
